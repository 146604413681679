<template>
    <div class="login-body">
        <div class="login-image">
            <img :src="'layout/images/pages/login-'+ loginColor + '.png'" alt="atlantis">
        </div>
      <form @keyup.enter="OnEnterKeyUp">
        <div class="login-panel p-fluid">
            <div class="flex flex-column">
                <div class="flex align-items-center mb-6 logo-container">
                    <img :src="'layout/images/logo-'+ color + '.png'" class="login-logo"/>
                    <img :src="'layout/images/appname-'+ color + '.png'" class="login-appname"/>
                </div>
                <div class="form-container" v-if="showSignIn">
                    <span class="p-input-icon-left">
                        <i class="pi pi-envelope"></i>
                        <InputText v-model:model-value="email" type="text" placeholder="Email" />
                    </span>
                    <span class="p-input-icon-left">
                        <i class="pi pi-key"></i>
                        <InputText v-model:model-value="password" type="password" placeholder="Password" />
                    </span>
                    <a href="#" class="flex" @click="onClickForgotPassword">Forgot your password?</a>
                </div>
              <div class="form-container" v-if="showCompleteNewPassword">
                <span class="p-input-icon-left">
                        <i class="pi pi-key"></i>
                        <InputText v-model:model-value="password" type="password" placeholder="Password" />
                    </span>
              </div>
              <div class="form-container" v-if="showForgotPassword">
                <span class="p-input-icon-left">
                        <i class="pi pi-envelope"></i>
                        <InputText v-model:model-value="email" type="email" placeholder="Email" />
                    </span>
                <a href="#" class="flex" @click="onClickCancelForgotPassword">Cancel</a>
              </div>
              <div class="button-container" v-if="showForgotPassword">
                <router-link to="/">
                  <Button type="button" label="Send reset email" @click="onClickSendResetEmail"></Button>
                </router-link>
              </div>
              <div class="form-container" v-if="showForgotPasswordSubmit">
                    <span class="p-input-icon-left">
                        <i class="pi pi-code"></i>
                        <InputText v-model:model-value="passwordResetCode" type="text" placeholder="Password reset code" />
                    </span>
                <span class="p-input-icon-left">
                        <i class="pi pi-key"></i>
                        <InputText v-model:model-value="password" type="password" placeholder="New password" />
                    </span>
              </div>
              <div class="button-container" v-if="showForgotPasswordSubmit">
                <router-link to="/">
                  <Button type="button" label="Set new password" @click="onClickForgotPasswordSubmit"></Button>
                </router-link>
              </div>
              <div class="button-container" v-if="showSignIn">
                    <router-link to="/">
                        <Button type="button" label="Login" @click="signIn"></Button>
                    </router-link>
                </div>
              <div class="button-container" v-if="showCompleteNewPassword">
                <router-link to="/">
                  <Button type="button" label="Set password" @click="completeNewPassword"></Button>
                </router-link>
              </div>
              <div class="flex" v-if="authError != null" style="color: red">
                <i class="pi pi-exclamation-circle mr-2"/>
                <span>
              {{ authError }}
            </span>
              </div>
            </div>

          <div class="login-footer flex align-items-center">
                <div class="flex align-items-center login-footer-logo-container">
                    <img src="layout/images/logo-gray.png" class="login-footer-logo"/>
                    <img src="layout/images/appname-gray.png" class="login-footer-appname"/>
                </div>
                <span>Copyright 2024</span>
            </div>
        </div>
      </form>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
    data() {
		return {
			email: '',
			password: '',
      passwordResetCode: '',
      user: null,
      showSignIn: true,
      showForgotPassword: false,
      showCompleteNewPassword: false,
      showForgotPasswordSubmit: false,
      authError: null,
		}
	},
    computed: {
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark';
            return 'onlight';
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
    },
  methods: {
    OnEnterKeyUp(){
      if(this.showSignIn)
      {
        this.signIn();
      }
      else if(this.showForgotPassword)
      {
        this.onClickSendResetEmail();
      }
      else if(this.showCompleteNewPassword)
      {
        this.completeNewPassword();
      }
      else if (this.showForgotPasswordSubmit)
      {
        this.onClickForgotPasswordSubmit();
      }
    },
    async signIn() {
      this.authError = null
      try {
         this.user = await Auth.signIn(this.email, this.password);
         if (this.user.challengeName == "NEW_PASSWORD_REQUIRED")
         {
           this.showSignIn = false
           this.showCompleteNewPassword = true
           this.password = ""
         }
      } catch (error) {
        console.log(error);
        this.authError = error["message"]
      }
    },
    async completeNewPassword() {
      this.authError = null
      try {
        await Auth.completeNewPassword(this.user, this.password);
      } catch (error) {
        console.log('error signing in', error);
      }
    },
    onClickForgotPassword() {
      this.authError = null
      this.showSignIn = false
      this.showForgotPassword = true
    },
    async onClickSendResetEmail() {
      this.authError = null
      try{
        await Auth.forgotPassword(this.email);
        this.password = ''
        this.showForgotPassword = false
        this.showForgotPasswordSubmit = true
      } catch (error) {
        console.log(error)
        this.authError = error["message"]
      }
    },
    async onClickForgotPasswordSubmit() {
      this.authError = null
      try {
        await Auth.forgotPasswordSubmit(this.email, this.passwordResetCode, this.password);
      } catch (error) {
        this.authError = error["message"]
      }
      this.user = await Auth.signIn(this.email, this.password);
    },
    onClickCancelForgotPassword() {
      this.authError = null
      this.showForgotPassword = false
      this.showSignIn = true
    }
  }
}
</script>