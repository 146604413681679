import {createRouter, createWebHashHistory} from 'vue-router';
import store from './store/index'
import { Hub } from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"

let user;

getUser().then((user) => {
    if (user) {
        router.push({path: '/'});
    }
});

function getUser() {
    return Auth.currentAuthenticatedUser().then((data) => {
        if (data && data.signInUserSession) {
            store.commit('setUser', data);
            return data;
        }
    }).catch(() => {
        store.commit('setUser', null);
        return null;
    });
}

Hub.listen("auth", async (data) => {
    if (data.payload.event === 'signOut'){
        user = null;
        store.commit('setUser', null);
        router.push({path: '/login'});
    } else if (data.payload.event === 'signIn') {
        user = await getUser();
        store.commit("setUser", user)
        router.push({path: '/'});
    }
});

const routes = [
    {
        path: '/',
        name: 'root',
        exact: true,
        component: () => import('./components/AqiMetricDashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistics', label: 'AQI Metric' }],
            requiresAuth: true
        },
    },
    {
        path: '/casebrowser',
        name: 'casebrowser',
        component: () => import('./components/CaseBrowser.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistics', label: 'Case Browser' }],
            requiresAuth: true
        },
    },
    {
        path: '/aqimetricdashboard',
        name: 'aqimetricdashboard',
        component: () => import('./components/AqiMetricDashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistics', label: 'AQI Metric' }],
            requiresAuth: true
        },
    },
    {
        path: '/hypotensionanalysis',
        name: 'hypotensionanalysis',
        component: () => import('./components/HypotensionAnalysisDashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistics', label: 'Hypotension Analysis (classic)' }],
            requiresAuth: true
        },
    },
    {
        path: '/hypotensionanalysisv2',
        name: 'hypotensionanalysisv2',
        component: () => import('./components/HypotensionAnalysisDashboardV2.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistics', label: 'Hypotension Analysis' }],
            requiresAuth: true
        },
    },
    {
        path: '/sluanalysis',
        name: 'sluanalysis',
        component: () => import('./components/SluAnalysisDashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Statistics', label: 'SLU Analysis' }],
            requiresAuth: true
        },
    },
    {
        path: '/menu',
        component: () => import('./components/MenusDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue'),
                meta: {
                    breadcrumb: [{ parent: 'UI Kit', label: 'Menu' }],
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeResolve(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        user = await getUser();
        if (!user) {
            return next({
                path: '/login'
            });
        }
        return next()
    }
    return next()
});

export default router;