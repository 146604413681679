import {createStore} from 'vuex'

export default createStore({
    state: {
        user: null,
        allSurgeries: [],
        filterState:'empty',
        startDate: null,
        endDate: null,
        minAge: null,
        maxAge: null,
        selectedFacilities: [],
        facilityOptions: [],
        selectedSurgeryTypes: [],
        surgeryTypeOptions: [
            "Cardiac",
            "Neuro",
            "General",
            "Urology",
            "Gynecology",
            "ENT",
            "Plastics",
            "Vascular",
            "Thoracic",
            "Orthopedics",
            "Other",
            " "
        ],
        allSelected: false,
        indeterminate: false,
        oldSelected:[]
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        submitFilter(state,args){
            if(args){
                state.filterState = 'filtered';
                state.startDate = args.startDate;
                state.endDate = args.endDate;
                state.minAge = args.minAge;
                state.maxAge = args.maxAge;
                for(let i=0; i<state.selectedSurgeryTypes.length;i++)
                {
                    state.selectedSurgeryTypes.pop();
                }
                state.selectedSurgeryTypes = [];
                args.surgeryType.forEach(item=>{
                    state.selectedSurgeryTypes.push(item.toString());
                });

                for(let i=0; i<state.selectedFacilities.length;i++)
                {
                    state.selectedFacilities.pop();
                }
                state.selectedFacilities = [];
                args.facilities.forEach(item=>{
                    state.selectedFacilities.push(item.toString());
                });
                state.allSelected = args.allSelected;
                state.indeterminate = args.indeterminate;
            }

        },
        clearFilter(state){
            state.filterState = 'empty';
            state.startDate = null;
            state.endDate = null;
            state.minAge = null;
            state.maxAge = null;
            for(let i=0; i<state.selectedSurgeryTypes.length;i++)
            {
                state.selectedSurgeryTypes.pop();
            }
            for(let i=0; i<state.selectedFacilities.length;i++)
            {
                state.selectedFacilities.pop();
            }
            state.allSelected = false;
            state.indeterminate = false;
        },
        submitSurgeryData(state,surgery){
            if(surgery){
                state.allSurgeries.push(surgery)
            }

        },
        submitFacilityOptions(state,facilities){
            //state.facilityOptions.forEach(state.facilityOptions.pop());
            if(facilities.length > 0)
            {
                let facilityOptionsEmpty = false;
                facilities.forEach((facilityOption)=>{
                    if (state.facilityOptions.length > 0){
                        if (facilityOptionsEmpty)state.facilityOptions.push(facilityOption);
                        else{
                            if(!state.facilityOptions.find(facilityOption)){
                                state.facilityOptions.push(facilityOption);
                            }
                        }

                    }
                    else{
                        facilityOptionsEmpty = true;
                        state.facilityOptions.push(facilityOption);
                    }

                });
            }
        },


    },
    getters: {
        isSignIn: (state) => {
            return state.user !== null
        },
        getFilteredSurgeries:(state)=>(surgeries)=> {
            let filteredRows = surgeries;
            if(state.filterState) {
                if (state.filterState.toString() !== 'empty') {

                    let selectedFacilitieslower = [];
                    state.selectedFacilities.forEach(item => {
                        selectedFacilitieslower.push(item.toString().toLowerCase().trim());
                    });

                    if (selectedFacilitieslower.length > 0) {
                        filteredRows = surgeries.filter(row => {
                            if (row.SGS_FacilityName == null)
                                return false
                            return (selectedFacilitieslower.includes(row.SGS_FacilityName.toLowerCase().toString().trim()))
                        });
                    }
                    let selectedSurgeryTypeslower = [];
                    state.selectedSurgeryTypes.forEach(item => {
                        selectedSurgeryTypeslower.push(item.toString().toLowerCase().trim());
                    });

                    if (selectedSurgeryTypeslower.length > 0) {
                        filteredRows = filteredRows.filter(row => {
                            if (row.SPI_SurgeryType == null)
                                return false
                            return (selectedSurgeryTypeslower.includes(row.SPI_SurgeryType.toLowerCase().toString().trim()))
                        });
                    }


                    return filteredRows
                        .filter(row => {
                            return ((!state.startDate || new Date(row.SGS_StartTime) >= new Date(state.startDate)) &&
                                (!state.endDate || new Date(row.SGS_StartTime) <= new Date(state.endDate))
                                &&
                                (!state.minAge || row.SPI_AgeMin >= state.minAge) &&
                                (!state.maxAge || row.SPI_AgeMax <= state.maxAge)

                            )
                        })
                } else
                    return filteredRows;
            }
            return filteredRows;
        },
        getAllSurgeries:(state)=>{
            return state.allSurgeries;
        },
        getCurrentUserName:(state)=>{
            return state.user?state.user.attributes?state.user.attributes.name:'':'';
        }
    },
    actions: {
        updateFilter(context,payload){
            context.commit('submitFilter',payload)
        },
        addFacilityOptions(context,payload){
            context.commit('submitFacilityOptions',payload)
        },
        emptyFacilityOptions(context){
            context.commit('clearFacilityOptions')
        },
        emptyFilter(context){
            context.commit('clearFilter')
        },
        updateSessionSurgeryData(context,payload){
            context.commit('submitSurgeryData',payload)
        },
    },
    modules: {
    }
})
