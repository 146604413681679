<template>
    <DataTable :value="tblItems" dataKey="id" class="p-datatable-gridlines">
      <Column field="MAP" header="MAP">
        <template #body="{data}">
          {{data.MAP}}
        </template>
      </Column>
      <Column field="Duration" header="Duration">
        <template #body="{data}">
          {{data.Duration}}
        </template>
      </Column>
    </DataTable>
</template>

<script>
export default {
  name: 'TargetTable',
  props: {
    secondsUnderTarget: Number,
    secondsInTarget: Number,
    secondsAboveTarget: Number,
  },
  computed: {
    totalSeconds() {
      return this.secondsUnderTarget + this.secondsInTarget + this.secondsAboveTarget
    },
    inTarget() {
      if (!this.totalSeconds || this.totalSeconds === 0) return ""
      var fraction = Math.round((this.secondsInTarget / this.totalSeconds) * 100).toFixed(
          0
      )
      var hours = Math.floor(this.secondsInTarget / 3600).toFixed(0)
      var mins = ((this.secondsInTarget % 3600) / 60).toFixed(0)
      return `${fraction}% (${hours}h ${mins}m)`
    },
    aboveTarget() {
      if (!this.totalSeconds || this.totalSeconds === 0) return ""
      var fraction = Math.round(
          (this.secondsAboveTarget / this.totalSeconds) *
          100
      ).toFixed(0)
      var hours = Math.floor(this.secondsAboveTarget / 3600).toFixed(0)
      var mins = ((this.secondsAboveTarget % 3600) / 60).toFixed(0)
      return `${fraction}% (${hours}h ${mins}m)`
    },
    underTarget() {
      if (!this.totalSeconds || this.totalSeconds === 0) return ""
      var fraction = Math.round(
          (this.secondsUnderTarget / this.totalSeconds) *
          100
      ).toFixed(0)
      var hours = Math.floor(this.secondsUnderTarget / 3600).toFixed(0)
      var mins = ((this.secondsUnderTarget % 3600) / 60).toFixed(0)
      return `${fraction}% (${hours}h ${mins}m)`
    },
    tblItems(){
      return[
        {id: 0, MAP:'Above Target',Duration:this.aboveTarget},
        {id: 1, MAP:'In Target',Duration:this.inTarget},
        {id: 2, MAP:'Under Target',Duration:this.underTarget}
      ];
    },
  },
}
</script>

<style scoped>
</style>
