<template>
	<div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" :src="'layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" alt="atlantis-layout"/>
            <span class="app-name">DS Aware&trade;</span>
            <span>&nbsp;&nbsp;</span>
            <span class="copyright">Version: {{ hdaArVersion }}</span>
          <span>&nbsp;&nbsp;</span>
          <span class="copyright" v-if="hdaArStage !== 'prod'">Stage: {{ hdaArStage }}</span>
        </div>
        <span class="copyright">&#169; Directed Systems Ltd - 2024</span>
    </div>
</template>

<script>
export default {
	props: {
		colorScheme: String
	},
  computed: {
    hdaArVersion() {
      const bitbucketPipelineBuildVersion = process.env.VUE_APP_BITBUCKET_BUILD_NUMBER
      const appVersion = require("../package.json").version
      return `${appVersion} (${bitbucketPipelineBuildVersion})`
    },
    hdaArStage() {
      let env = process.env.VUE_APP_ENV;
      if (!env) return "Unknown env";
      else return env;
    },
  },
}
</script>

