<template>
	<div class="layout-breadcrumb-container">
		<div class="layout-breadcrumb-left-items">
			<a href="#" class="menu-button" @click="onMenuButtonClick($event)" v-if="isStatic()">
				<i class="pi pi-bars"></i>
			</a>

			<Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb>
		</div>
		<div class="layout-breadcrumb-right-items">
      <div class="hospital-name-wrapper">
        <i class="pi pi-building p-icon-left"></i>
        <span>{{ accountName }}</span>
      </div>
		</div>
	</div>
</template>

<script>
import {API} from "aws-amplify";

export default {
	props: {
		menuMode: String,
		searchActive: {
			type: Boolean,
			default: false
		},
		searchClick: {
			type: Boolean,
			default: false
		}
	},
	emits: ['menubutton-click', 'rightmenu-button-click', 'update:searchActive', 'update:searchClick'],
	data() {
		return {
			items: [],
			search: '',
      accountName: "Unknown account name"
		}
	},
	watch: {
		$route() {
			this.watchRouter();
		}
	},
	created() {
		this.watchRouter();
	},
  mounted() {
    API
      .get("TestAPI", "/account-info", null)
      .then(response => {
        this.accountName = response["AccountName"]
      })
      .catch(error => {
        console.log(error.response);
        this.accountName = "Error retrieving account name"
      })
  },
	methods: {
		watchRouter() {
			if(this.$router.currentRoute.value.meta.breadcrumb) {
				this.items = [];
				const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
				for(let pro in bc) {
					this.items.push({label: bc[pro]});
				}
			}
		},
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		onRightMenuButtonClick() {
			this.$emit('rightmenu-button-click');
		},
		inputClick() {
			this.$emit('update:searchClick', true);
		},
		breadcrumbClick() {
			this.$emit('update:searchActive', true);
			this.$emit('update:searchClick', true);
		},
		isStatic() {
			return this.menuMode === 'static';
		}
	},
}
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) { 
	display: none;
}
</style>